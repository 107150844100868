import React from 'react';
import {Description, GraphWrapper} from "../../timeline/report/graphes/graphesStyle";
import {Bar} from "react-chartjs-2";
import useGetGraphData from "./hooks/useGetGraphData";
import {tt} from "../../../utils/LangUtil";

const options = {
  maxBarThickness    : 10,
  maintainAspectRatio: true,
  scales             : {
    y: {
      grid: {
        display: true,
        color  : '#272727',
      },

    },
  },
  plugins            : {
    legend : {
      display: false,
    },
    tooltip: {
      yAlign         : 'bottom',
      callbacks      : {
        title: function (tooltipItems, data) {
          return;
        },
        label: function (tooltipItems, data) {
          return `${tooltipItems.formattedValue}시간`;
        },
      },
      bodyColor      : 'black',
      bodyFont       : {weight: 'bold'},
      backgroundColor: 'white',
      displayColors  : false,
    },
  },
};

const YearStatistics = ({data}) => {
  const {barData} = useGetGraphData(data);

  return (
    <div>
      <Description>
        <span className="comparison">
          {tt("최근 12개월간 안전운전 점수")}
        </span>
      </Description>
      <GraphWrapper>
        <Bar
          height={480}
          width={1000}
          options={options}
          data={barData}
        />
      </GraphWrapper>
    </div>
  );
};

export default YearStatistics;
