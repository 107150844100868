import React from 'react';
import styled from "styled-components";
import ImgHandle from "../../../assets/img/img_handle.png";
import {format} from "../../../utils/DateUtil";
import {tt} from "../../../utils/LangUtil";

const DrivingInfo = ({data}) => {
  // TODO: data?.timestamp === "";
  const day = data?.timestamp ? new Date(data.timestamp) : "";

  return (
    <div>
      {day && <HistoryDate>{format(day, "YYYY-MM-DD")}</HistoryDate>}
      <DataWrapper>
        <div>
          <img src={ImgHandle} alt=""/>
        </div>
        <div>
          <TitleGray>{tt("안전운전 점수")}</TitleGray>
          <ValueStrong>{data?.safeDriveScore || "-"}</ValueStrong>
        </div>
        <div>
          <TitleGray>{tt("급가속")}</TitleGray>
          <ValueStrong>{data?.accelCount !== undefined ? data.accelCount : "-"}</ValueStrong>
        </div>
        <div>
          <TitleGray>{tt("급감속")}</TitleGray>
          <ValueStrong>{data?.decelCount !== undefined ? data.decelCount : "-"}</ValueStrong>
        </div>
      </DataWrapper>
      {day && (
        <Description>
          {tt("안전운전 점수는 급가속, 급감속을 포함한 운전자의 운행패턴을 기반으로 산출됩니다.")}
        </Description>
      )}
      {!day && (
        <NoneDataTxt>
          <BlockTxt>
            {tt("안전운전 점수를 표시할 수 없습니다.")}
          </BlockTxt>
            {tt("인포 장착 후 1일 이상 운전을 해야 안전운전 점수가 산출됩니다.")}
          <Warning>
            {tt("※ 인포 장착 후 1일 이상 운전을 했는데도 안전운전 점수가 나오지 않는 경우, 아차키 고객지원센터에 문의해 주십시오.")}
          </Warning>
        </NoneDataTxt>
      )}
    </div>
  );
};

export default DrivingInfo;

const HistoryDate = styled.p`
  text-align    : center;
  font-size     : 0.813rem;
  margin-top    : 10px;
  margin-bottom : 10px;
`

const DataWrapper = styled.dl`
  display         : flex;
  align-items     : center;
  justify-content : space-around;
  margin-bottom   : 5px;

  img {
    width : 24px;
  }
`

const TitleGray = styled.dt`
  font-size     : 0.813rem;
  color         : #8E8E93;
  margin-bottom : 2px;
`;

const ValueStrong = styled.dd`
  font-weight : bold;
  text-align  : center;
`;

const AverageGray = styled.span`
  font-size   : 0.813rem;
  font-weight : normal;
  color       : #8E8E93;
`;

const Description = styled.small`
  font-size  : 0.813rem;
  color      : #8E8E93;
  word-break : break-word;
`;

const NoneDataTxt = styled.p`
  margin-top  : 20px;
  font-size   : 1.06rem;
  line-height : 1.2rem;
`;

const BlockTxt = styled.span`
  display : block;
`;

const Warning = styled.strong`
  display    : block;
  margin-top : 20px;
  color      : #FF6961;
`;
