import lang from "../lang/lang.json";
import * as CookieUtil from "./CookieUtil";

const activeLocale = CookieUtil.getUserLanguage();

const format = (str, params) => {
  let result = str;

  if (params) {
    Object.keys(params).forEach((key) => {
      let value = params[key];
      const template = new RegExp(`{${key}}`, "gm");

      if (value === null || value === undefined) {
        value = "";
      }
      result = result.replace(template, String(value));
    });
  }

  return result;
};

export const tt = (key, params) => {
  let value = null;
  if (!key || !lang[key]) {
    // translate하지 않으면 여기로 빠짐
    // lang[key]가 undefined 이므로 바로 에러떨어짐
  } else {
    // if (activeLocale !== "ko") {
    //   value = lang[key]["en"];
    // } else {
    //   value = lang[key][activeLocale];
    // }

    switch (activeLocale) {
      case "ko":
      case "en":
      case "in":
      case "th":
        value = lang[key][activeLocale];
        break;
      default:
        value = lang[key]["en"];
        break;

    }
  }

  if (!value) {
    value = key;
    // console.warn(`text not found : ${key}`);
  }
  return format(value, params);
};
